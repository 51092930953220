import { RemixBrowser } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { StrictMode, startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'

Sentry.init({
  dsn: window.ENV.SENTRY_DSN,
  enabled: window.ENV.APP_ENV === 'prod',
  environment: window.ENV.APP_ENV,
  tunnel: '/monitoring'
})

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  )
})
